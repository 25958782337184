<template>
    <Image src="layout/images/duon_logo_azul.svg" alt="Logo formando a palavra Duon" width="100" class="mt-6" />
    <span class="font-bold mt-4 poppins-medium text-center mx-3" style="font-size: 28px"> Boas vindas as preferência de consulta </span>
    <span class="text-center mt-2 mx-4" style="font-size: 18px"> Para agendar sua consulta, complete os campos com suas credencias </span>
    <div class="mt-4 w-full">
        <label class="text-2xl text-700" for="cpf"> CPF </label>
        <InputMask
            id="cpf"
            v-model="cpf"
            :autoClear="false"
            :class="{ 'p-invalid': v$.cpf.$error }"
            autocomplete="off"
            class="w-full border-2 border-round-xl p-4 mt-1 text-xl font-medium"
            mask="999.999.999-99"
            placeholder="Digite seu cpf"
        />
        <small v-if="v$.cpf.$error" class="p-error">
            {{ v$.cpf.$errors[0].$message }}
        </small>
    </div>
    <div class="mt-4 w-full">
        <label class="text-2xl text-700" for="token"> Token </label>
        <InputText
            id="token"
            v-model="token"
            :class="{ 'p-invalid': v$.token.$error }"
            class="w-full border-2 border-round-xl p-4 mt-1 text-xl font-medium"
            placeholder="Digite seu token"
            type="text"
            v-mask="['XXXXXXX-############']"
        />
        <small v-if="v$.token.$error" class="p-error">
            {{ v$.token.$errors[0].$message }}
        </small>
    </div>
    <div class="mt-4 w-full">
        <Checkbox
            v-model="termoAceito"
            :binary="true"
            :inputStyle="{ width: '23px', height: '23px' }"
            inputClass="shadow-none"
            inputId="termos"
            style="width: 23px; height: 23px"
        />
        <label for="termos" class="w-full ml-1 text-xl">
            Concordo com os <span style="text-decoration: underline; color: #3f51b5" @click="modalTermo = true"> termos e condições </span>
        </label>
    </div>
    <small v-if="v$.termoAceito.$error" class="p-error w-full">
        {{ v$.termoAceito.$errors[0].$message }}
    </small>
    <Button
        :loading="loading"
        class="border-none text-2xl w-full mt-6 py-3 poppins-medium"
        label="Entrar"
        rounded
        style="background-color: #3f51b5"
        @click="validarFormulario()"
    />

    <Dialog
        v-model:visible="modalTermo"
        :closable="false"
        :showHeader="false"
        contentClass="border-round-xl py-4 text-lg text-justify"
        style="width: 700px; max-width: 98vw; max-height: 600px"
        modal
    >
        <h4>Termo de Aceite para Acesso à Plataforma</h4>
        <p>
            Este Termo de Aceite ("Termo") estabelece as condições sob as quais você ("Funcionário" ou "Usuário") concorda em acessar e utilizar a
            plataforma oferecida por DSG Technology ("Nós", "Nosso" ou "A Empresa").<br />
            Leia atentamente este Termo, pois seu acesso e uso da Duon ("Plataforma") estão sujeitos a essas disposições.
        </p>
        <h5>Termos e Condições:</h5>
        <p>
            Responsabilidade do Funcionário: Ao utilizar a Plataforma, você concorda em fornecer informações precisas e atualizadas conforme
            solicitado durante o processo de registro. <br /><br />
            Segurança da Conta: Você é responsável pela segurança de suas credenciais de conta. Não compartilhe sua senha ou outras informações de
            acesso com terceiros.<br /><br />
            Uso Adequado da Plataforma: Você concorda em utilizar a Plataforma de maneira ética e legal. Não deve realizar atividades que violem leis
            aplicáveis ou os direitos de terceiros. <br /><br />
            Privacidade e Dados Pessoais: A Empresa se compromete a proteger suas informações pessoais de acordo com as leis de privacidade
            aplicáveis. Consulte nossa Política de Privacidade para obter informações detalhadas sobre como tratamos seus dados. <br /><br />
            Atualização dos Termos: A Empresa se reserva o direito de atualizar este Termo periodicamente. Você será notificado sobre quaisquer
            alterações significativas, e seu uso contínuo da Plataforma após tais alterações constitui aceitação dos novos termos. <br /><br />
            Aceitação: Ao clicar no botão "Aceito" ou realizar qualquer ação que indique a aceitação destes termos na Plataforma, você confirma que
            leu, entendeu e concorda em ficar vinculado por este Termo.
        </p>
        <div class="flex justify-content-end">
            <Button
                :loading="loading"
                class="border-none mt-4 ml-full py-3 px-6 text-xl poppins-medium"
                label="Confirmar"
                rounded
                style="background-color: #3f51b5"
                @click="modalTermo = false"
            />
        </div>
    </Dialog>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { ref, defineEmits } from 'vue';
import { cpf as validateCpf } from 'cpf-cnpj-validator';
import { loginAutoagendamento } from '@/services/auth';
import { useToast } from 'primevue/usetoast';
import InputText from 'primevue/inputtext';

const emit = defineEmits(['prevPage', 'nextPage']);

const cpf = ref('');
const token = ref('');
const loading = ref(false);
const termoAceito = ref(false);
const modalTermo = ref(false);
const toast = useToast();

const rules = {
    cpf: {
        required: helpers.withMessage('CPF deve ser informado', required),
        isValid: helpers.withMessage('CPF inválido', (cpf) => validateCpf.isValid(cpf))
    },
    token: {
        required: helpers.withMessage('Token deve ser informada', required)
    },
    termoAceito: {
        required: helpers.withMessage('Aceite o termo para continuar', (aceite) => !!aceite)
    }
};
const v$ = useVuelidate(rules, { cpf, token, termoAceito });

async function validarFormulario() {
    const formularioCorreto = await v$.value.$validate();
    if (formularioCorreto) fazerLogin();
}
async function fazerLogin() {
    loading.value = true;
    try {
        await loginAutoagendamento(cpf.value.replace(/\D/gim, ''), token.value, termoAceito.value);
        emit('nextPage');
    } catch (error) {
        const mensagem = error?.response?.data?.message ?? 'CPF ou token inválidos!';
        toast.add({
            severity: 'error',
            summary: mensagem,
            life: 3000
        });
    }
    loading.value = false;
}
</script>
<style></style>
